<script setup lang="ts">
import type { MenuItem } from '~/types'

export type LegalDocName = 'Impressum' | 'Nutzungsbedingungen' | 'Privatsphäre'
const legalDocs: LegalDocName[] = ['Impressum', 'Nutzungsbedingungen', 'Privatsphäre']

const emit = defineEmits<{
  (e: 'legal-open', value: LegalDocName): void
}>()

const localesOptions = computed<MenuItem[]>(() => legalDocs.map(name => ({
  label: name,
  command: () => {
    emit('legal-open', name)
  },
})))
</script>

<template>
  <UiDropdownSelect
    key="code"
    :options="localesOptions"
    placement="top-start">
    <template #title>
      <span class="desc">Legal</span>
    </template>
  </UiDropdownSelect>
</template>
